@import '../../../styles/variables.sass'
@import '../../../styles/colors.sass'


.header
  background: linear-gradient(270deg, #003C77 11%, #1B65A2 35%, #1D6DAE 53%, #1F75BB 73%)
  padding: 10px 0
  margin: 0
  height: 150px
  display: flex
  justify-content: center

  .content
    display: flex
    justify-content: space-between
    align-items: center
    width: 1250px
    padding: 0 8px


    .left-container
       display: flex
       align-items: center

       .logo
        padding-right: 60px
        width: 155px
       span
        font-size: 1.5rem
        font-family: $default-font
        color: $lennar-text-white

  
    .right-container > .options
      display: flex
      align-items: center
      justify-content: flex-end
      height: 44px
      padding-bottom: 14px
      > *
        margin-left: 16px

  .ant-btn-default
    background: $default-light-gray-2
    &:hover,
    &:focus
      background: $lennar-brand-blue
      background-color: $lennar-brand-blue
  .ant-btn-primary
    &:hover,
    &:focus
      background: $default-light-gray-2
      border-color: $lennar-dark-blue


  .btn-new-corePlan
    font-style: normal
    font-weight: 500
    font-size: 1rem
    line-height: 20px
    height: 65px
    width: 200px
    border-radius: 10px
    color: #003C77
    border: 2px solid #003C77
    background: #FFF
    margin-bottom: 12px

    &:hover
      border-color: #fff
      color: #fff


  .btn-rooms
    background-color: transparent
    border-color: transparent
    max-width: 32px
    min-width: 32px
    width: 32px
    height: 32px
    margin: 0 0 0 0
    padding: 0
    &:hover
      background-color: transparent
      border-color: transparent
    &:focus
      background-color: transparent
      border-color: transparent

  .btn-export
    background-color: transparent
    border-color: transparent
    max-width: 32px
    min-width: 32px
    width: 60px
    height: 32px
    padding: 0
    &:hover
      background-color: transparent
      border-color: transparent
    &:focus
      background-color: transparent
      border-color: transparent