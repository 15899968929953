@import '../../../../styles/colors'

.system-settings-modal
  width: 1035px !important
  transform: translate(0, -50%)!important
  top: 50%!important
  max-height: 100vh
  padding: 0
  

  .ant-form.ant-form-horizontal.settings-tab
   height: 470px
  .ant-row.settings-panel 
   max-height: calc(100% - 60px)
   height: calc(100% - 60px)

   padding-bottom: 8px
   overflow: auto

  .ant-modal-header
    padding: 40px 0
    margin: 0 32px
  .ant-modal-close
    top: 36px
  .ant-modal-body
    padding: 0 32px 0
  .ant-btn
    padding: 14px 24px
    font-size: 16px
    line-height: 21px
    min-width: 108px
  .ant-tabs-nav
    padding: 30px 0 30px
    margin-bottom: 30px
    border-bottom: 1px solid $gray
    &::before
      content: none
    .ant-tabs-nav-list
      flex-wrap: wrap
      row-gap: 20px
  .ant-tabs-tab
    display: flex
    justify-content: center
    background: $gray
    border: none
    padding: 6px 32px 7px
    border-radius: 50px
    color: $lennar-text
    margin: 0 10px
    text-shadow: none
    width: auto
    min-width: 132px
    &:first-child
      margin-right: 70px

    .ant-tabs-tab-btn
      font-weight: 400
      font-size: 14px
      line-height: 20px
      color: $lennar-text
    &.ant-tabs-tab-active
      background: $lennar-button-hover
      .ant-tabs-tab-btn
        color: $lennar-text-white
  .ant-tabs-ink-bar, .ant-modal-footer
    display: none
  .buttons-row
    margin: 30px 0
    float: right
    .ant-btn:not(:last-child)
      margin-right: 10px
  div[id$="validations"]
    .buttons-row
