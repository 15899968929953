.wall-properties
    .section
        font-size: 16px
        font-weight: 500

    .title
        font-size: 13px
        font-weight: 600

    .border-bottom
        border-bottom: 1px solid #CCC

    .properties
        padding: 20px 30px

        &.disabled
            background-color: #FFFFFF80
            pointer-events: none
            opacity: 0.3

        .item
            position: relative
            width: 100%
            display: flex
            justify-content: space-between
            font-size: 14px
            color: #6A6A6A
            font-weight: 400
            padding-top: 15px
            text-align: right

            .error
                color: #FF0606
    
    .wall-width-dropdown
        position: absolute
        cursor: pointer
        right: -15px
        background: url("../../../../../../public/assets/icons/union-black.svg") no-repeat 100% 8px / 10px auto
        height: 20px
        padding-right: 15px

        svg
            display: none

    .disabled
        pointer-events: none
        cursor: default
        opacity: 0.5

    .button-group
        display: flex
        gap: 2px
        margin-top: 16px

    .icon-button
        display: flex
        width: 28px
        height: 28px
        justify-content: center
        align-items: center
        background-color: #F4F4F4
        border: none
        cursor: pointer
        border-radius: 4px


    .icon-button:hover
        background-color: #E0F1FE
        color: #1F75BB
        border: 2px solid #1F75BB

    .icon-button:active
        background-color: #1F75BB
        color: #FFFFFF
        border: 2px solid #1F75BB
        
    .icon
        width: 20px
        height: 20px

