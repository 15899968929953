@import '../../../../styles/colors'

$lg-screen-breakpoint: 991px
.tab-details
  h5
    font-weight: 500
    font-size: 14px
    line-height: 20px
    margin-bottom: 8px
  .ant-row
    position: relative
    &::after
      content: ""
      position: absolute
      top: 0
      left: 50%
      width: 1px
      height: 100%
      background: $gray
  hr
    height: 1px
    background-color: $gray
    border: 0 none
    padding: 0
    margin: 27px 0 27px 0
  .corePlan-name
    margin-bottom: 14px
    h5
      margin: 0
  .corePlan-address
    margin-bottom: 14px
    h5
      margin: 0
    span
      margin-top: -4px
      font-weight: 500
      font-size: 12px
      line-height: 20px
      color: $gray1
  p
    margin-bottom: 14px
    font-weight: 400
    font-size: 12px
    line-height: 20px
  .client
    h5
      margin: 0
  .corePlan-overview-column
    border-right: none
    &:first-of-type
      @media (min-width: 992px)
        border-right: 1px solid $gray
  .corePlan-details
    padding: 0 20px 0 42px
    @media (max-width: $lg-screen-breakpoint)
      padding: 0 0 20px 42px
      margin-bottom: 30px
      border-bottom: 1px solid $gray
  .corePlan-notes
    padding-right: 30px
    margin-bottom: 33px
    p
      white-space: pre-line
      &:last-child
        margin-bottom: 0

.corePlan-attributes
  padding: 0 91px 0 76px
  color: $lennar-brand-blue
  @media (max-width: $lg-screen-breakpoint)
    padding: 0 28px 0 42px
  .cost-attributes, .area-attributes
    list-style: none
    margin: 0 0 47px 0
    padding: 0
    @media (max-width: $lg-screen-breakpoint)
      margin-bottom: 20px
    li
      display: inline-block
      & + li
        padding-left: 51px
    span
      font-size: 22px
      line-height: 20px
      &.title
        font-weight: 500
        font-size: 14px
        line-height: 20px
        margin-bottom: 11px
        display: block
      &.units
        font-size: 18px
  .cost-attributes, .area-attributes
    @media (max-width: $lg-screen-breakpoint)
      margin-right: 0
    margin: 26px -80px 0 0
    justify-content: space-between
    &::after
      content: none
    .outdated-warning
      font-size: 12px
      color: $red2
  .area-attributes
    .ant-col:not(:last-child)
      margin-right: 8px
  .params
    list-style: none
    margin: 0
    padding: 0
    li
      font-weight: 500
      font-size: 14px
      line-height: 20px
      padding: 15px 0 15px 33px
      display: flex
      justify-content: space-between
      align-items: center
      border-bottom: 1px solid $gray2
      position: relative
      .icon
        display: block
        position: absolute
        width: 16px
        height: 16px
        top: 0
        bottom: 0
        left: 0
        margin: auto
        background-repeat: no-repeat
        background-position: left center
        background-size: auto
        &.icon-floors
          background-image: url('./../../../../../public/assets/icons/attributes/floors.svg')
        &.icon-garage
          background-image: url('./../../../../../public/assets/icons/attributes/garage.svg')
        &.icon-bedrooms
          background-image: url('./../../../../../public/assets/icons/attributes/bedrooms.svg')
        &.icon-floorHeight
          background-image: url('./../../../../../public/assets/icons/attributes/floorHeight.svg')
        &.icon-bathrooms
          background-image: url('./../../../../../public/assets/icons/attributes/bathrooms.svg')
      .value
        font-size: 20px
        line-height: 24px