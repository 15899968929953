@import '../../../../../styles/colors'

.materials-collapse-item.ant-collapse-item
  margin-bottom: 4px
  .ant-collapse-header
    background: lighten($lennar-brand-blue, 15%)
    padding: 8px 32px
    .ant-collapse-header-text
      color: $white
      font-size: 16px
      line-height: 21px
    .ant-collapse-expand-icon
      right: 30px
      background: url("../../../../../../public/assets/icons/union.svg") no-repeat center center / 16px
      transform: rotate(-90deg)
      span
        display: none
  &.ant-collapse-item-active .ant-collapse-header .ant-collapse-expand-icon
    transform: rotate(0deg)
  .ant-collapse-content-box
    padding-top: 16px
    padding-left: 32px

  .upload-collapse-item
    margin-right: -30px
    margin-bottom: 16px
    .upload-button
      padding: 13px
    .warning-message-wrapper
      padding-top: 8px
      .warning-message
        color: $red
        font-size: 12px
    .ant-collapse-header
      background: unset
      padding-left: 0px
      .ant-collapse-header-text
        padding: 0
        font-style: normal
        font-size: 14px
        line-height: 16px
        color: $lennar-brand-blue
        font-weight: unset
        & .subtitle
          border-bottom: 1px dotted lighten($lennar-brand-blue, 25%)
      .ant-collapse-expand-icon
        right: 0
        background: url("../../../../../../public/assets/icons/union-black.svg") no-repeat center / 7px
        transform: rotate(-90deg)
        span
          display: none
    &.ant-collapse-item-active
      .ant-collapse-header
        .ant-collapse-header-text
          color: $teal
          & .subtitle
            border-bottom-color: lighten($teal, 25%)
        .ant-collapse-expand-icon
          transform: rotate(0deg)
    .ant-collapse-content-box
      padding-top: 4px
      padding-left: 0px

  .actions
    position: absolute
    top: 0
    right: 68px
    .ant-btn
      background: lighten($lennar-brand-blue, 15%)
      border-color: lighten($lennar-brand-blue, 15%)
      padding: 4px
      min-width: auto
      height: 37px
      &:hover, &:active
        background: lighten($lennar-brand-blue, 15%)
        border-color: lighten($lennar-brand-blue, 15%)
