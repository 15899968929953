.icon
  display: block
  width: 20px
  height: 20px
  background-position: center
  background-size: 12px auto
  background-repeat: no-repeat
  background-color: transparent
  margin-right: 4px

  &.btn-icon:disabled
    opacity: 0.4
    cursor: not-allowed
    &:hover
      background-color: transparent
  &.icon-generate
    background-image: url("./../../public/assets/icons/generate.svg")
  &.icon-mirror
    background-image: url("./../../public/assets/icons/mirror-gray.svg")
  &.icon-rotate
    background-image: url("./../../public/assets/icons/rotate-gray.svg")
  &.icon-select
    background-image: url("./../../public/assets/icons/select.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/select-white.svg")
  &.icon-select.inverted
    background-image: url("./../../public/assets/icons/select-white.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/select.svg")
  &.icon-rotate
    background-image: url("./../../public/assets/icons/rotate.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/rotate-white.svg")
  &.icon-rotate2
    background-image: url("./../../public/assets/icons/rotate2.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/rotate2-white.svg")
  &.icon-contentrotate
    background-image: url("./../../public/assets/icons/contentrotate.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/contentrotate-white.svg")
  &.icon-contentrotate2
    background-image: url("./../../public/assets/icons/contentrotate2.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/contentrotate2-white.svg")
  &.icon-mirror
    background-image: url("./../../public/assets/icons/mirror.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/mirror-white.svg")
  &.icon-mirror2
    background-image: url("./../../public/assets/icons/mirror2.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/mirror2-white.svg")
  &.icon-back
    background-image: url("./../../public/assets/icons/back.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/back-white.svg")
  &.icon-front
    background-image: url("./../../public/assets/icons/front.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/front-white.svg")
  &.icon-floors
    background-image: url("./../../public/assets/icons/floors.svg")
  &.icon-bedrooms
    background-image: url("./../../public/assets/icons/bedrooms.svg")
  &.icon-bathrooms
    background-image: url("./../../public/assets/icons/bathrooms.svg")
  &.icon-garage
    background-image: url('./../../public/assets/icons/garage.svg')
  &.icon-variations
    background-image: url('./../../public/assets/icons/variations.svg')
  &.icon-trash
    background-image: url("./../../public/assets/icons/trash.svg")
    background-size: 16px auto
    &.icon-trash-upload-list
      width: 30px
    &:focus
      outline: none
      box-shadow: none
  &.icon-plus
    background-image: url("./../../public/assets/icons/plus.svg")
    background-size: 16px auto
  &.icon-union
    background-image: url("./../../public/assets/icons/union.svg")
  &.icon-settings
    background-image: url("./../../public/assets/icons/Gear.svg")
  &.icon-edit-lotline
    background-image: url("./../../public/assets/icons/editlotline.svg")
  &.icon-paint-brush-blue
    background-image: url("./../../public/assets/icons/paint-brush-blue.svg")
  &.icon-measure
    background-image: url("./../../public/assets/icons/ruler.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/ruler-white.svg")
  &.icon-measure.inverted
    background-image: url("./../../public/assets/icons/ruler-white.svg")
    &:hover:not(:disabled)
      background-image: url("./../../public/assets/icons/ruler.svg")
