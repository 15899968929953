$primary-error-color: #FF3B47
$secondary-error-color: #F6BCBF

.modelling-issues-error-container
  display: flex
  align-items: center

  .error-message
    font-size: 10px
    color: $primary-error-color
    margin-right: 7px

    &.secondary
      color: $secondary-error-color

  .error-ring
    width: 16px
    height: 16px
    border-radius: 50%
    border: 3px solid $primary-error-color

    &.secondary
      border: 3px solid $secondary-error-color

    .error-inner-ring
      width: 100%
      height: 100%
      border-radius: 50%
      border: 2px solid $secondary-error-color
