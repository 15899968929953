@import '../../../styles/colors'
@import '../../../styles/variables.sass'

$delete-file-button-width: 48px
.veev-upload
  .ant-upload-list-text-container
    display: flex
    *:hover
      background-color: unset
    .ant-upload-text-icon:hover
      background-color: $teal
    .ant-upload-list-item-card-actions-btn
      min-width: $delete-file-button-width
      padding: 0
  .ant-upload-list-text-container
    .ant-upload-list-item-card-actions
      &:hover,
      &:focus,
      &:active,
      &:visited
        background-color:  $teal
  .ant-upload-text-icon
    width: 35px
    height: 22px
  .ant-upload-list-item-name
    @media (max-width: $modal-corePlan-default-screen-width)
      max-width: calc(50vw - (4 * $delete-file-button-width))
    max-width: 352px
    padding: 0 17px 0 8px
    color: $default-dark-gray
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  .ant-upload
    display: block
    .ant-upload-select-text
      margin-bottom: 12px
    .ant-btn.upload
      padding: 15px 20px
      font-weight: 500
      font-size: 14px
      line-height: 20px
      border-color: $gray
      background: url('./../../../../public/assets/icon-cloud.svg') no-repeat right 16px center / 32px auto
      .btn-delete,
      .btn-delete:hover
        background: url('./../../../../public/assets/trash.svg') no-repeat center / 12px auto
      .btn-delete:hover
        background-color: $white

  &.disabled
    opacity: 0.75
    .ant-upload
      .ant-btn.upload
        cursor: default
