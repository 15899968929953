@import '../../../../../styles/colors'

.configure-tab
  position: relative
  height: 100%
  padding-left: 10px
  padding-top: 6px
  overflow: hidden

  .roof-list, .finish-list
    max-height: calc(100vh - 410px)
    width: 100%
    overflow: auto
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    padding-right: 14px

  .dutch-gable-input-container
    display: flex
    justify-content: space-between
    align-items: center
    span
      color: #0D2727
      font-size: 12px
      font-weight: 500
    .width-input
      border-radius: 1.5px
      border: solid 0.5px #B1B1B1
      width: 62px
      min-width: 62px
      height: 14px
      padding: 16px 0 16px 4px
      font-size: 12px
      


  .btn-container
    position: sticky
    bottom: 20px
    width: calc(100% - 20px)
  h5
    font-weight: 500
    font-size: 14px
    line-height: 20px
    margin-bottom: 12px
    color: #003C77
  .ant-select
    width: 100%
    height: 42px
    margin-bottom: 16px
    .ant-select-selector
      height: 42px
      background-color: $default-light-gray-1
      .ant-select-selection-item
        line-height: 42px
  .padding:not(:first-of-type)
    margin-top: 30px
  .padding
    padding-left: 10px
    height: calc(100% - 85px)
    width: 100%
    overflow: auto

    .ant-collapse-header 
      display: flex
      flex-direction: row-reverse
      align-items: center
      font-size: 16px
      font-weight: 500
    
    .header-container
      display: flex
      align-items: flex-end
      
      img
        margin-right: 10px

    .ant-collapse-arrow 
      order: 1
      margin-left: auto
      margin-right: 16px

    .ant-collapse-content,
    .ant-collapse-item,
    .custom-collapse
      border: none
      background: #fafafa
      max-height: 100%

    .roof-list,.finishes-list
      padding-bottom: 6px
      max-height: calc(100vh - 410px)
      width: 100%
      overflow: auto
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      padding-right: 14px

  .material-name,
  .description,
  .roof-label
    display: block // Ensures the label is on a new line
  .ant-btn
    font-weight: 400
    font-size: 12px
    line-height: 15.62px
  .description
    max-height: 110px
    white-space: pre-line
    width: 100%
    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical

  .material-image
    height: 122px
    width: 122px
    object-fit: cover

  .material-image-selected
    height: 122px
    width: 122px
    border: 2px solid #005DAA
    display: flex
    justify-content: center
    align-items: center

    i
      width: 63px
      height: 63px
      background-size: 63px

  .material-image,
  .material-image-selected,
  .material-name, .roof-label
    margin-bottom: 4px
    font-size: 12px

  .ant-btn
    width: 100%
    height: 42px
    padding: unset
    border-width: 1px
    &:not(:last-child)
      margin-bottom: 12px

.roof-image
  height: 122px
  width: 122px
  background-size: contain
  background-repeat: no-repeat // Prevents the image from repeating
  background-position: center // Centers the image within the element

.roof-image-selected
  height: 122px
  width: 122px
  position: relative // Add this to make it a positioning context
  background-repeat: no-repeat
  background-position: center
  background-size: contain
  display: flex
  justify-content: center
  align-items: center
  border: 2px solid #005DAA // Add border here
  background-color: #ffffff30

.gable-image
  background-image: url("./../../../../../../public/assets/icons/gable.svg")

.dutch-gable-image
  background-image: url("./../../../../../../public/assets/icons/dutch-gable.svg")

.hip-image
  background-image: url("./../../../../../../public/assets/icons/hip.svg")

.add-variation-btn
  margin-top: 20px // Adjust the value as needed
  background: #003c77
