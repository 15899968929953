@import '../../../styles/variables.sass'
@import '../../../styles/colors.sass'
@import '../../../styles/icons.sass'

.ant-tooltip 
    max-width: 320px

.editor-page
  height: 100vh
  display: flex
  flex-direction: column
  align-items: stretch

.editor-header
  flex: 0 0 auto
  background: $default-light-gray-1
  display: flex
  z-index: 5
  align-items: center
  .ScrollbarsCustom-Wrapper
    inset: 0 16px !important

  .right-continer
    width: 100%
    display: flex
    justify-content: flex-end

    &>div
      max-width: 400px

  .error-container
    display: flex
    justify-content: space-between
    align-items: center
    font-size: 12px
    overflow: hidden
    width: 315px
    margin-right: 20px

    div
      color: #FC0303
      text-align: end
      padding-right: 8px

    span
      font-weight: 500

  .home-button
    flex: 0 0 auto
    padding: 8px 16px
    max-width: $leftBarWidth
    min-width: $leftBarWidth
    font-size: 1rem
    .img-container
      cursor: pointer
      height: 100%
      padding: 2px 0 7px 0

  .editor-header-toolbar
    flex: 1 1 auto
    padding: 8px 0
    display: flex
    align-items: center
    justify-content: space-between

  .group
    display: flex
    align-items: center

  .dropdown-dimension-type,
  .dropdown-corePlan,
  .dropdown-floor,
  .dropdown-grid,
  .dropdown-lotline,
  .dropdown-visibility
    &::after
      content: none
    .ant-btn-icon-only
      padding: 0
      height: auto
      width: auto
      border: none
      min-width: 32px
    .ant-btn-default,
    &.ant-btn-default
      text-align: left
      border: none
      background: $default-light-gray-1
      color: $lennar-brand-blue !important
      background: transparent
      &:hover
        background: $default-light-gray-1
        color: $lennar-brand-blue
      &::after
        content: none
  
  .dropdown-corePlan
    justify-content: flex-end
    display: flex
    flex-direction: row-reverse
    .anticon-down
      margin-left: 5px
    .ant-btn
      font-weight: 500
      font-size: 16px
      line-height: 21px
      padding: 8px 8px
      text-align: left
      width: auto
      min-width: 20px
      cursor: default
    .ant-btn-icon-only
      padding: 0 2px 0 20px
      cursor: pointer
      span
        width: 9px
        height: 16px
      svg
        display: none
    .ant-btn-icon-only,
    .ant-btn-icon-only:hover
      background-image: url("../../../../public/assets/icons/subtract.svg"), url("../../../../public/assets/icons/union-black.svg")
      background-repeat:  no-repeat
      background-position: left center, left 20px center,
      background-size: 16px 16px, 9px auto

  .dropdown-dimension-type,
  .dropdown-floor,
  .dropdown-grid,
  .dropdown-lotline,
  .dropdown-visibility
    font-style: normal
    font-weight: 500
    font-size: 10px
    line-height: 20px
    padding: 0
    display: flex
    align-items: center
    min-width: 30px
    .anticon
      background: url("../../../../public/assets/icons/union-black.svg") no-repeat center left / 7px auto
      width: 7px
      height: 20px
      svg
        display: none

  .dropdown-visibility
    .anticon
      padding-left: 28px
      background-image: url("../../../../public/assets/icons/eye.svg"), url("../../../../public/assets/icons/union-black.svg")
      background-repeat: no-repeat
      background-position: center left, center left 20px
      background-size: 14px auto, 7px auto
      height: 20px
  .dropdown-grid
    .anticon
      padding-left: 28px
      background-image: url("../../../../public/assets/icons/table-outlined.svg"), url("../../../../public/assets/icons/union-black.svg")
      background-repeat: no-repeat
      background-position: center left, center left 20px
      background-size: 14px auto, 7px auto
      height: 20px
  .dropdown-dimension-type
    .anticon
      padding-left: 28px
      background-image: url("../../../../public/assets/icons/dimension.svg"), url("../../../../public/assets/icons/union-black.svg")
      background-repeat: no-repeat
      background-position: center left, center left 20px
      background-size: 14px auto, 7px auto
      height: 20px
  .dropdown-lotline
    .anticon
      padding-left: 28px
      background-image: url("../../../../public/assets/icons/lotline.svg"), url("../../../../public/assets/icons/union-black.svg")
      background-repeat: no-repeat
      background-position: center left, center left 20px
      background-size: 14px auto, 7px auto
      height: 20px

  .icon-upload-background
    font-size: 10px
    padding: 0px
  .icon-cloud
    width: 30px
    min-width: 30px
    height: 16px
    padding: 10px 15px
    background: url("../../../../public/assets/icons/cloud.svg") no-repeat center center / 24px auto
    border: none !important
    span
      display: none
  .icon-zoom-to-fit
    width: 30px
    min-width: 30px
    height: 16px
    padding: 10px 15px
    background: url("../../../../public/assets/icons/extend.svg") no-repeat center center / 24px auto
    border: none !important
    span
      display: none
  .icon-alignment
    width: 30px
    min-width: 30px
    height: 16px
    padding: 0px 10px
    border: none !important

  .ant-dropdown
    color: $lennar-brand-blue
    & > *
      color: $lennar-brand-blue
  .ant-radio-group
    display: flex
  .ant-btn-group
    align-items: center
    .ant-btn.btn-minus,
    .ant-btn.btn-plus
      width: 16px
      min-width: 16px
      height: 16px
      padding: 0
      display: flex
      align-items: center
      justify-content: center
      border-radius: 0
      border-width: 1px
      background: transparent
      &:hover,
      &:focus,
      &:active
        background-color: $lennar-brand-blue
        border-color: $lennar-brand-blue
      .anticon
        line-height: 0
        font-size: 9px
    .ant-btn.btn-plus
      border-left: 0
    .dropdown-zoom
      font-weight: 500
      &.ant-btn
        border: none
        padding: 4px 0 4px 5px
        min-width: 30px
        background: transparent
        font-size: 10px
        line-height: 20px
        margin: 0 16px 0 0
        display: flex
        align-items: center
        &:hover,
        &:focus-visible
          color: $lennar-brand-blue
        .anticon
          background: url("../../../../public/assets/icons/union-black.svg") no-repeat center left / 9px auto
          width: 9px
          height: 20px
          margin-left: 5px
          svg
            display: none
  .btn-mode
    .ant-radio-button-wrapper
      width: auto
      min-width: auto
      padding: 4px 24px
      font-weight: 400
      font-size: 16px
      line-height: 21px
      height: auto
      border-radius: 0
      border-color: #000 !important
      box-shadow: none
      box-shadow: none !important
        
      &:hover
        background: #003c77
        color: #fff

      span
        display: inline-block
        vertical-align: middle

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
     background: #003c77

     &:hover
      background: #003c77
      color: #fff

.editor-header-overlay
  position: absolute
  top: 100px
  left: 400px
  z-index: 10

  p
    color: #979797
    font-size: 10px
    font-weight: 500
    text-align: center
    margin-bottom: 0
    user-select: none
  hr
    margin: 6px 0 6px 0
    border: none
    height: 1px
    background-color: #43434359
  .ant-btn
    border-radius: 13px
    background-color: $white
    border: 1px solid
    display: flex
    align-items: center
    padding: 0
    color: $lennar-brand-blue
    font-size: 10px
    font-weight: 500
    width: 100%
    min-width: 124px
    margin-bottom: 0.5em

    .icon-container
      width: 24px
      height: 24px
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      margin: 0px 10px 0px -1px
      color: white
  .button-success
    border-color: $lennar-outlines
    .icon-container
      background-color: $lennar-dark-blue
  .button-failed
    border-color: $red
    .icon-container
      background-color: $red

.editor-content
  flex: 1 1 auto
  display: flex
  align-items: stretch
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  min-height: 0px
.editor-left-panel
  flex: 0 0 auto
.editor-right-panel
  flex: 1 1 auto
  display: flex
  flex-direction: column

.editor-scene-holder
  flex: 1 1 auto
  position: relative
  overflow: hidden

.editor-canvas
  position: absolute
  &.hidden
    visibility: collapse
.de-viewer-iframe
  width: 100%
  height: 100%
  border: none
  position: absolute

.editor-footer
  flex: 0 0 auto
  height: 46px
  z-index: 5
  background: $default-light-gray-1
  color: $lennar-brand-blue
  border-top: 1px solid $default-medium-gray-1
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  .editor-footer-toolbar
    padding: 12px 0
    display: flex
    align-items: center
    justify-content: space-between
  .ScrollbarsCustom-Wrapper
    inset: 0 16px !important
  .group
    display: flex
    align-items: center
  .item
    display: flex
  
  .ant-input
    width: 60px
    padding: 0 4px
    font-weight: 500
    font-size: 10px
    line-height: 18px
    border-color: $default-medium-gray-1
    background: $white
    color: $lennar-brand-blue

  p
    font-weight: 500
    font-size: 10px
    line-height: 20px
    color: $lennar-brand-blue
    margin: 0
    span
      padding: 0 4px
      display: inline-block
      &:first-child
        padding-left: 0
      &:last-child
        padding-right: 0
      &.space
        padding-right: 4px
    em
      font-style: normal
      padding: 0 4px
      &.last
        padding-right: 0

  .ant-input-group-addon,
  span
    color: $default-medium-gray-2
    font-size: 10px
    line-height: 20px

  .ant-input-group-addon
    font-weight: 400

  .ant-input-group-addon,
  p
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  //@media(max-width: 1179px)
  //  width: 100%
  //  position: relative

  .icon-rotate
    background-image: url("./../../../../public/assets/icons/rotate-gray.svg")
    &:hover:not(:disabled)
      background-image: url("./../../../../public/assets/icons/rotate-gray.svg")
  .icon-mirror
    background-image: url("./../../../../public/assets/icons/mirror-gray.svg")
    &:hover:not(:disabled)
      background-image: url("./../../../../public/assets/icons/mirror-gray.svg")

.toolbutton-separator
  width: 16px

.editor-toolbar-container
  position: absolute
  z-index: 10
  overflow: auto
  display: flex
  transform: translateX(-50%)
  border-radius: 20px
  box-shadow: 0px 2px 6px 0px #0000001A
  border: 0.5px solid #00000040

.editor-toolbar
  background: #fff 
  z-index: 10
  overflow: auto
  display: flex
  margin: auto
  display: flex
  justify-content: center
  align-items: center
  hr
    background: $default-medium-gray-1
    margin: 0 2px
    height: 16px
    width: 2px
    border: none
  .btn-icon
    border: none
    border-radius: 0
    width: 28px
    height: 28px
    display: block
    background-size: 16px auto
    cursor: pointer
    &:hover
      filter: brightness(0) saturate(100%) invert(32%) sepia(100%) saturate(750%) hue-rotate(180deg) brightness(90%) contrast(100%)

      span.anticon
        color: $white
    span.anticon
      color: $lennar-brand-blue
  button
    border: none
    width: 100%
    height: 100%

  .btn-icon.pressed
    background-color: $lennar-brand-blue
    span.anticon
      color: $white
    &:hover
      background-color: rgba(0, 0, 0, 0)
      border: 2px solid $lennar-brand-blue
      span.anticon
        color: $lennar-brand-blue

.ant-dropdown-menu
  padding: 0

  .measure-icon
    width: 24px
    margin: 0
  .scale-icon
    width: 16px
  li.ant-dropdown-menu-item
    padding: 8px 24px
    font-weight: 400
    font-size: 12px
    line-height: 16px
    border-bottom: 1px solid $default-medium-gray-1
    min-width: 161px
    &.ant-dropdown-menu-item-active,
    &:hover
      background-color: rgba($lennar-button-hover, 0.2)
    &:focus
      background: $white
    &:last-child
      border-bottom: none

.logo-container
  display: flex
  align-items: center

.ellipsis-title
  display: block
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  width: 80%
  margin-left: 24px

.enclosed-select-box
  position: fixed
  border: 1px solid #0662ac
  background-color: #0662ac26

.enclosed-and-intersected-select-box
  position: fixed
  border: 1px solid #288346
  background-color: #28834626
.room-type-ghost-image
  position: fixed
  opacity: 0.5
  pointer-events: none
