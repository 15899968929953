@import '../../../styles/colors.sass'

.corePlan-lock-modal
  top: 300px

  .ant-modal-footer
    border-top: none

    .ant-btn
      min-width: 100px
      min-height: 40px
      padding: 8px 16px

