@import '../../../../../styles/colors'

.validation-panel
  margin: 0 10px
  .validation-panel-tabs>.ant-tabs-nav
    margin-bottom: 22px
    .ant-tabs-tab
      height: 42px

  .validation-type-panel
    &.one-tab
      .ant-tabs-tab
        width: 100%
    .ant-tabs .ant-tabs-nav
      margin-bottom: 30px !important
      &::before
        border-bottom: none
      .ant-tabs-tab
        height: 32px
    .validation-tab
      .level-areas
        margin-top: 20px
        .legend-text
          margin-bottom: 4px
          padding-bottom: 4px
        .area-row
          font-size: 12px
          font-weight: 400
          color: #6A6A6A
          display: flex
          justify-content: space-between
          margin-bottom: 6px
      .area-items-title
        border-bottom: solid 1px #D8D8D8
        padding-bottom: 6px
      .validation-items, .area-items
        padding: 0 24px
      .validation-items
        margin-bottom: 24px
      .cladding-checkbox
        display: flex
        margin-bottom: 16px
      .legend-text
        font-size: 12px
        font-weight: 500
        line-height: 20.83px
        padding-bottom: 8px
    .legend
      border-top: 1px solid $default-medium-gray-1
      padding-top: 20px
      font-size: 12px
      line-height: 16px
      .min-capacity-text
        position: absolute
        margin-top: -16px
      .min-capacity-line
        width: 4px
        height: 50px
        z-index: 1
        background-color: $lennar-brand-blue
        position: absolute
        &.red
          background-color: $red
      table
        table-layout: fixed
        width: 100%
        th, td
          padding-top: 4px
          padding-bottom: 4px
        th
          overflow: hidden
          white-space: nowrap
          font-weight: 400
          &:first-child
            text-align: left
          &:last-child
            text-align: right
        th:hover, td:hover
          max-width: none !important
        tbody
          border:1px solid $lennar-brand-blue
          td
            border: 1px solid $lennar-brand-blue
            text-align: center
            position: relative
            .cell-content
              overflow: hidden
              text-overflow: clip
              white-space: nowrap
          .tooltip
            display: none
            position: absolute
            top: 50%
            left: 50%
            z-index: 1
            background-color: #E4E4E4
            border: 1px solid $lennar-outlines
            padding: 8px 16px
            font-size: 16px
            color: $lennar-tooltip-background
            white-space: nowrap
          td:hover span.tooltip
            display: inline
          .total-row
            color: $white
            background-color: $lennar-brand-blue
          .points-row .cell-content
            white-space: pre-wrap
