@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap')

@font-face 
  font-family: 'Inter'
  src: url("../../public/assets/fonts/Inter-VariableFont.ttf") 
  font-weight: regular
  font-style: regular

@font-face 
  font-family: 'Inter'
  src: url("../../public/assets/fonts/Inter-VariableFont.ttf") 
  font-weight: medium
  font-style: medium

@font-face 
  font-family: 'Inter'
  src: url("../../public/assets/fonts/Inter-VariableFont.ttf") 
  font-weight: semiBold
  font-style: semiBold