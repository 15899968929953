@import '../../../../styles/colors'

.tab-corePlan-files
  margin-top: -10px
  .item, .job-item
    border: 1px solid $gray
    background: $gray3
    padding: 0 15px
    margin-bottom: 12px
    .title
      display: flex
      align-items: center
      justify-content: flex-start
      padding: 19px 0
      .icon
        width: 19px
        height: 24px
        min-width: 19px
        margin: 0 17px 0 15px
        position: relative
        background: url('./../../../../../public/assets/file.svg') no-repeat center / 19px auto
        span
          position: absolute
          top: 7px
          left: -7px
          font-weight: 400
          font-size: 8px
          line-height: 10px
          text-transform: uppercase
          padding: 0 3px
      p
        font-weight: 400
        font-size: 14px
        line-height: 20px
        margin: 0
        overflow-wrap: anywhere
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
    .details
      border-top: 1px solid $gray
      display: flex
      align-items: center
      justify-content: space-between
      padding: 6px 0
      p
        margin: 0
        font-weight: 500
        font-size: 8px
        line-height: 24px
        color: $gray4
      .btn-file
        padding: 0
        border: 0
        margin: 0 5px 0
        background: none
        width: 24px
        height: 24px
        min-width: 24px
        &:hover
          box-shadow: 0px -2px 2px rgba($gray5, 0.25)
          border-radius: 3px
          background: $white
      .btn-download,
      .btn-download:hover
          background: url('./../../../../../public/assets/download.svg') no-repeat center / 14px auto
      .btn-download:hover
        background-color: $white
      .btn-delete,
      .btn-delete:hover
        background: url('./../../../../../public/assets/trash.svg') no-repeat center / 12px auto
      .btn-delete:hover
        background-color: $white
    .file-viewer-icon
      position: absolute
      right: 20px
      top: 8px
      &:hover
        cursor: pointer
  .job-item
    .job-status-icon,.spinner
      width: 19px
      height: 24px
      min-width: 19px
      margin: 0 17px 0 15px
      position: relative
      padding-top: 4px
      font-size: 16px
      color: $red
    .job-info-icon
      position: absolute
      right: 20px
      top: 8px
      &:hover
        cursor: pointer
      &.ant-tooltip-open
        background: none

.render-job-error
  padding-bottom: 24px