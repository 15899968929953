@import '../../../../styles/colors.sass'

.editor-background-selector
  .image-item
    padding: 2px
    border: 3px solid transparent
    &:hover
      cursor: pointer
      border: 3px solid green
  .pdf-item
    position: relative
    border: 3px solid transparent
    &:hover
      cursor: pointer
      border: 3px solid green
    .anticon
      position: absolute
      right: 20px
      top: 8px
  .pdf-pages-view
    .title-row
      cursor: pointer
      margin-bottom: 12px
      .anticon
        position: relative
        width: 22px
        margin-right: 12px
        svg
          position: absolute
          top: 4px
    .pdf-page
      padding: 2px
      border: 3px solid transparent
      &:hover
        cursor: pointer
        border: 3px solid green

