@import '../../../styles/colors'

.modal-add-rooms
  width: 864px !important
  .ant-modal-content
    border: none
    border-radius: 0
  .ant-modal-close,
  .ant-modal-header
    display: none
  .ant-modal-body
      padding: 40px 80px
  .ant-modal-footer
    border: none
  .upload-state-icon
    font-size: 150%
    &.info
      margin-left: 10px
      background: unset
  .warning-message
    color: $red
