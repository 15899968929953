@import '../../../styles/colors'

.tooltip-container
  position: absolute
  left: 0
  top: 0
  color: $lennar-text-white
  text-align: center
  pointer-events: none
  padding: 0px 2px
  background: transparent
  border: none
  box-shadow: none
  transition: opacity 0.25s linear
  border-radius: 1px