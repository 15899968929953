@import '../../../../../../styles/colors'

$finish-image-size: 122px
$finish-image-border: 3px

.finishes-list
  .finish-item
    .image-container
      height: $finish-image-size + 2 * $finish-image-border
      width: $finish-image-size + 2 * $finish-image-border
      border: $finish-image-border solid transparent
      transition: border-color 0.33s
      &:hover,
      &:active
        border: $finish-image-border solid $lennar-outlines
        cursor: pointer
      img
        height: $finish-image-size
        width: $finish-image-size
        object-fit: cover
    &.selected
      .image-container
        border: $finish-image-border solid #2a98e1a4
    .finish-name
      margin-bottom: 10px
