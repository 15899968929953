@import '../../../styles/colors.sass'

.container
  margin-top: 5px
  cursor: pointer

ul.menu
  padding: 0
  li
    padding: 16px!important
    min-width: 80px!important

    &:hover,
    &:focus
      background-color: $default-light-gray-3 !important
