@import '../../../../styles/colors'

.room-management-tab
  height: 470px!important
  .category-container
    max-height: calc(100% - 60px)
    height: calc(100% - 60px)
    padding-bottom: 8px
    overflow: auto


  .placeholder-section
    text-align: center
    margin-top: 32px
  .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon
    position: absolute
    top: 0
    bottom: 0
    margin: auto
    width: 20px
    height: 20px
    transform: rotate(-90deg)
    transition: transform 0.3s linear
    span
      display: none
  .ant-collapse-content, .ant-collapse-item
    border: none
  .category-collapse-item
    margin-bottom: 4px
    .ant-collapse-header
      background: $lennar-brand-blue
      padding: 8px 32px
      .ant-collapse-header-text
        color: $white
        font-size: 16px
        line-height: 21px
      .ant-collapse-expand-icon
        right: 30px
        background: url("../../../../../public/assets/icons/union.svg") no-repeat center center / 16px
        transform: rotate(-90deg)
        span
          display: none
    &.ant-collapse-item-active .ant-collapse-header .ant-collapse-expand-icon
      transform: rotate(0deg)
    .function-collapse-item
      margin-right: -30px
      .ant-collapse-header
        background: unset
        padding-left: 0
        .ant-collapse-header-text
          padding: 0
          font-style: normal
          font-size: 12px
          line-height: 16px
          color: $lennar-brand-blue
          font-weight: unset
        .ant-collapse-expand-icon
          right: 0
          background: url("../../../../../public/assets/icons/union-black.svg") no-repeat center / 7px
          transform: rotate(-90deg)
          span
            display: none
      &.ant-collapse-item-active
        .ant-collapse-header
          .ant-collapse-header-text
            color: $teal
          .ant-collapse-expand-icon
            transform: rotate(0deg)
      .ant-collapse-content-box
        margin-right: -60px
        margin-left: -60px
  .ant-collapse
    border: none
    background: transparent
  .ant-collapse-content-box
    border: none
    background: transparent
    padding: 0 60px
    p
      margin: 0
  .actions
    position: absolute
    top: 0
    right: 68px
    .ant-btn
      padding: 4px
      min-width: auto
      height: 37px
      &:hover, &:active
        background: $lennar-brand-blue
        border-color: $lennar-brand-blue
  .room-types-list
    .room-type-item
      width: 25%
      padding-top: 24px
      img
        min-height: 144px
        height: 144px
        border: 0.1rem solid transparent
        transition: border-color 0.33s
        &:hover,
        &:active
          border: 0.1rem solid $lennar-brand-blue
          cursor: pointer
      span
        font-size: 8px
        line-height: 20px
        display: block
    .room-type-item.selected
      img
        border: 0.1rem solid $lennar-outlines
  .bulk-upload-button
    margin-right: 10px
    .ant-upload
      width: unset
      .ant-btn
        background: $lennar-brand-blue
        border-color: $lennar-brand-blue
        color: $white
        border-width: 2px
        &:hover
          background: $white
          border-color: $lennar-brand-blue
          color: $lennar-brand-blue
