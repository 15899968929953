@import '../../../../styles/colors'
@import '../../../../styles/variables'

.left-bar
  width: $leftBarWidth
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  background: $default-light-gray-1

  .ant-tabs-content-holder
    overflow: auto

  .tabs-holder
    flex: 1 1 auto
    min-height: 0
    .ant-tabs-content
     height: 100%

  .buttons-holder
    position: sticky
    bottom: 0
    flex: 0 0 auto
    background: $default-light-gray-1
    border-width: 1px 0 0 0
    border-style: solid
    border-color: $default-medium-gray-1
    line-height: 1
    display: flex
    justify-content: space-evenly
  .btn
    display: flex
    align-items: center
    font-size: 1rem
    color: #002645
    cursor: pointer
    background: transparent
    padding: 12px 16px
    border: none
    text-align: left
    box-shadow: none
    position: relative
    min-width: 111px
    max-width: 50%
    &:focus
      color: $lennar-brand-blue
    .icon-container
      width: 22px
      margin-right: 8px

      img
        width: 100%
    &:disabled
      opacity: 0.5
      cursor: not-allowed

  .left-bar-tabs
    height: 100%
    padding: 0 8px
    &>.ant-tabs-nav
      padding-bottom: 32px
      margin-bottom: 16px
      &::before
        border-bottom: 1px solid $default-medium-gray-1
    .ant-tabs-nav-list
      width: 100%
    .ant-tabs-tab
      margin: 0
      font-size: 12px
      border: 1px solid $lennar-brand-blue
      text-align: center
      width: 50%
      border-left: 0
      height: 45px
      &:first-child
        border-left: 1px solid $lennar-brand-blue
      & > *
        color: $lennar-brand-blue
      &:hover
        color: $lennar-brand-blue
      .ant-tabs-tab-btn
        width: 100%
        display: block
      &.ant-tabs-tab-active
        background: $lennar-brand-blue
        .ant-tabs-tab-btn
          color: $white
          font-weight: normal
    .ant-tabs-ink-bar,
    .ant-tabs-nav-operations
      display: none !important
    .ant-tabs-tab-disabled .ant-tabs-tab-btn
      color: $default-medium-gray-2

.export-plans-dropdown
  box-shadow: 0px -6px 8px rgba(0, 0, 0, 0.1)
  width: 189px
  ul
    padding: 12px 0
    li.ant-dropdown-menu-item
      padding: 12px 28px 12px 52px
      font-weight: 400
      font-size: 12px
      line-height: 16px
      border: none
      background-repeat: no-repeat
      background-position: 26px center
      background-size: 16px 16px
      &:hover,
      &:focus,
      &.ant-dropdown-menu-item
        background-repeat: no-repeat
        background-position: 26px center
        background-size: 16px 16px
      &.pdf
        background-image: url('./../../../../../public/assets/icon-pdf.svg')
      &.json
        background-image: url('./../../../../../public/assets/icon-json.svg')
      &.tml
        background-image: url('./../../../../../public/assets/icon-tml.svg')
      &.render
        background-image: url('./../../../../../public/assets/icons/attributes/floorHeight.svg')
