@import '../../../../../styles/colors'

.product-params
  .ant-list
    padding: 0 32px
    .ant-list-item
      padding: 16px 0
      font-style: normal
      font-weight: 400
      font-size: 16px
      line-height: 21px
      .ant-list-item-meta-description
        color: $lennar-brand-blue
    &.list-info
      padding: 0
      .ant-list-item
        display: block
        padding: 8px 34px
        border: none
        color: $lennar-brand-blue
        font-weight: 500
        line-height: 20px
        .item-title
          font-size: 14px
          margin-bottom: 6px
        .item-value
          font-size: 22px
          .unit
            font-size: 18px
        &:last-child
          padding-bottom: 8px
      .spinner-container
        z-index: 0
  .title-row
    justify-content: space-between
    padding: 6px
    background: $lennar-brand-blue
    color: $white
    .text
      font-style: normal
      font-weight: 400
      font-size: 14px
      line-height: 20px
    .anticon
      cursor: pointer
      padding: 3px 8px 0 0
      &[disabled]
        color: $default-dark-gray
        cursor: not-allowed
      svg
        height: 14px
  .outdated-warning
    margin: 4px 34px
    margin-bottom: 0
    font-size: 12px
    color: $red2
