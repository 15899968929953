@import './variables.sass'

.ant-btn
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 20px
  height: auto
  border-radius: 20px
  border-width: 2px
  min-width: 136px
  padding: 20px 32px
  &:active
    color: inherit
    border-color: inherit
  box-shadow: none
  &:hover
    &::after
      content: none
.ant-btn-primary
  border-color: $lennar-primary-button-unselected
  background: $lennar-primary-button-unselected
  &:hover,
  &:focus
    background: $white
    color: $lennar-primary-button-unselected
.ant-btn-primary-round
  border-color: $lennar-primary-button-unselected
  &:hover,
  &:focus
    background: $white
    color: $lennar-primary-button-unselected
.ant-btn-default
  border-color: $lennar-primary-button-unselected
  &:hover,
  &:focus
    background: $lennar-primary-button-unselected
    border-color: $white
