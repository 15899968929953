@import '../../../styles/colors'

.corePlan-request
  .lot-line-inputs,.coverage-inputs
    color: $lennar-dark-blue
    .ant-input-group-addon
      font-size: 10px
      font-weight: 400
    .ant-input-group-addon:first-child
      text-align: left
      min-width: 48px
      padding: 0 4px 4px 0
      color: $lennar-text
    .ant-input-group-addon:last-child
      color: $default-medium-gray-2
      padding: 0 0 4px 4px
    .ant-input
      border-width: 1px
      width: 50px
      height: 20px
      min-width: unset
      font-size: 10px
      font-weight: 500
      &:hover, &:focus
        border-right: 1px solid $gray
  .lot-line-inputs
    .lot-attributes-title
      font-weight: 500
      padding-bottom: 11px
    .ant-form-item
      margin-bottom: 10px
    .ant-form-item-control-input
      min-height: unset
  .ant-form-item
    margin: 0 0 27px 0
  .ant-input
    padding: 9px 7px
    color: $lennar-brand-blue
    border-color: $gray
    border-width: 0 0 1px
    font-weight: 500
    font-size: 14px
    line-height: 20px
    &:hover:not(textarea),
    &:focus:not(textarea)
      border-right: none
  input
    &.ant-input-status-success, &.ant-input-status-error
      width: 70px !important
  textarea
    &.ant-input
      border-width: 1px
      padding: 14px 22px 14px 12px
      font-size: 12px
      font-weight: 400
      line-height: 20px
      min-height: 198px !important
  .ant-form-item-label
    padding: 0
    &.mid-label
      margin-top: 10px // Adjust the margin value as needed
    label
      font-weight: 500
      font-size: 14px
      line-height: 20px
      margin-bottom: 10px
    &.label-upload
      margin-bottom: 0px
  .form-group
    margin-bottom: 7px
    .text
      font-size: 12px
      font-weight: 400
      &.red
        color: $red2
      &:last-of-type
        margin-bottom: 22px
  .btn-next
    background: none
    border: none
    color: $lennar-brand-blue
    font-weight: 500
    font-size: 14px
    line-height: 20px
    padding: 0
    span
      margin-right: 16px
    span,
    i
      display: inline-block
      vertical-align: middle
  .icon-next
    border-radius: 50%
    width: 33px
    height: 33px
    background: $lennar-brand-blue
    font-weight: 400
    font-size: 18px
    line-height: 33px
    color: $white
    font-style: normal
    margin: 0
  .form-group.group-upload
    margin-bottom: 37px
    .veev-upload
      margin-bottom: 12px

  .corePlan-saving-spinner
    position: fixed

  .attributes-group
    padding-left: 0px
    &.group-2
      display: flex
      flex-wrap: wrap
      .ant-form-item-label
        padding-left: 20px
      .group-garage
        .ant-radio-button-wrapper
          min-width: 77px
    .ant-form-item-label
      padding-left: 25px
      padding-top: 18px
      background-repeat: no-repeat
      background-size: 16px auto
      background-position: left center
      &.label-floors
        background-image: url("../../../../public/assets/icons/attributes/floors.svg")
        background-size: 12px auto
      &.label-bedrooms
        background-image: url("../../../../public/assets/icons/attributes/bedrooms.svg")
      &.label-bathrooms
        background-image: url("../../../../public/assets/icons/attributes/bathrooms.svg")
      &.label-garage
        background-image: url("../../../../public/assets/icons/attributes/garage.svg")
        background-size: 14px auto
      &.label-net-floor-to-ceiling
        background-image: url("../../../../public/assets/icons/attributes/floorHeight.svg")
        background-size: 12px auto
      &.label-coverages
        background-image: url("../../../../public/assets/icons/attributes/coverage.svg")
        background-size: 12px auto
    label
      font-weight: 500
      font-size: 14px
      line-height: 20px
    .form-group
      margin-bottom: 30px
      &.floor-to-ceiling-group
        margin-bottom: 16px
    .ant-radio-group-outline
      border-left: 1px solid $default-dark-gray
      padding-bottom: 1px
      margin-bottom: 10px
    .ant-radio-button-wrapper
      font-weight: 700
      font-size: 12px
      line-height: 16px
      padding: 10px 6px
      border-left: none
      height: auto
      max-height: 48px
      min-width: 40px
      margin-bottom: -1px

  .ant-radio-group
    padding-right: 15px
    padding-top: 0px
    display: flex
    flex-direction: row

  .btn-finish
    padding: 13px 37px
    font-weight: 700
    font-size: 12px
    line-height: 16px
    min-width: 118px
    margin-top: 50px

  .ant-btn-primary
    background: #003C77
    &:hover
      background: #fff
      
  .ant-btn-default
    &:hover
     background: #003C77
     border-color: #1F75BB

