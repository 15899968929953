@import '../../../../../styles/colors'

.validation-item
  display: flex
  align-items: center
  height: 58px
  font-size: 12px
  border-bottom: 1px solid $default-medium-gray-1
  width: 100%
  &:last-child
    border-bottom: none
  &.compact
    border-bottom: none
    height: 36px
  .title
    display: inline-block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  .ant-tooltip-open
    background: none
  .dot
    display: inline-block
    min-width: 6px
    width: 6px
    height: 6px
    border-radius: 6px
    margin-left: 16px
    margin-right: 34px
  .segment, .dashedSegment
    display: inline-block
    min-width: 6px
    width: 38px
    height: 5px
    margin-right: 18px
  .dashedSegment
    background: repeating-linear-gradient(90deg, transparentize($white, 1) 0 10px, $white 0 14px)
  .room
    width: 38px
    height: 23px
    margin-right: 18px
  .area
    width: 23px
    height: 23px
    margin-right: 18px
  .plumbingPoint
    .point
      display: inline-block
      width: 12px
      height: 12px
      border-radius: 6px
    .point-segment
      display: inline-block
      position: relative
      margin-left: -4px
      width: 20px
      height: 5px
      bottom: 3px
    .ring
      display: inline-block
      margin-left: -2px
      width: 12px
      height: 12px
      border-radius: 6px
      border-width: 3px
      border-style: solid
      margin-right: 18px
  .dashedLine
    display: inline-block
    width: 38px
    border-bottom: 1px dashed $default-dark-gray
    margin-right: 18px
