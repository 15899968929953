// AttributeSelector.sass
@import '../../../../styles/colors'
.attribute-selector
    //padding-left: 25px
      padding-top: 18px
      
.form-item-label
  display: flex
  justify-content: flex-start
  align-items: center
  margin-bottom: 10px
  margin-top: 10px
  line-height: 20px
  font-weight: bold
  font-size: 14px

  .icon
    display: flex
    width: 20px
    height: 20px
    background-position: center
    background-size: 12px auto
    background-repeat: no-repeat
    background-color: transparent
    margin-right: 4px

.radio-group
  display: flex

  .radio-button-wrapper
    border-radius: 0
    //border: 1px solid #d9d9d9
    //margin-right: 4px
    padding: 0 8px
    font-size: 14px

    &:last-child
      margin-right: 0

    &-checked
      background-color: #1890ff
      color: #fff
      border-color: #1890ff
