@import '../../../../../styles/colors'

.manage-tab
  .item
    margin-top: 10px
    margin-right: 5px
    border: 1px solid $gray
    background: $gray3
    padding: 0 15px 10px 15px
    padding-right: 100px
    border-radius: 10px

    .title
      display: flex
      align-items: center
      justify-content: flex-start
      padding: 19px 0px 0px 0px
      p
        font-weight: 400
        font-size: 14px
        line-height: 20px
        overflow-wrap: anywhere

    .icon-button
      border: none
      position: absolute
      top: 10px
      padding: 0
      text-align: center
      background: transparent
      box-shadow: none
      min-width: 40px
      max-width: 50%
      display: flex
      align-items: center
      justify-content: center
      height: 40px
      span.icon
        background-size: cover
        width: 24px
        height: 24px
        margin: auto
        display: block
      &:hover
        box-shadow: 0px -2px 2px rgba($gray5, 0.25)
        border-radius: 3px
        background: $white
      &:focus
        color: $lennar-brand-blue

    .btn-delete
      right: 10px
      span.icon
        background-image: url('./../../../../../../public/assets/icons/trash.svg')
        filter: brightness(1) invert(1) // This converts the image to black
      &:disabled
        opacity: 0.5
        cursor: not-allowed

    .snapshot-thumbnails
      display: flex
      gap: 10px  // Add spacing between images

    .snapshot-thumbnail
      width: 145px
      height: auto

    .material-thumbnails
      display: grid
      grid-template-columns: repeat(5, 1fr)  // Max 5 thumbnails per row
      gap: 10px  // Add spacing between images
      margin-top: 10px

    .material-thumbnail
      width: 48px
      height: 48px