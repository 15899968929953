@mixin fonts-input
  font-weight: 500
  font-size: 18px
  line-height: 20px

.edit-attribute
  .ant-input-group-addon
    @include fonts-input
    padding: 0 8px
  .ant-input
    @include fonts-input
    min-width: 60px
    width: 120px
