@import '../../../styles/colors'

.spinner
  display: inline-block
  &:after
    content: ' '
    display: block
    border-radius: 50%
    border: 6px solid teal
    border-color: teal transparent teal transparent
    animation: spinner 1.2s linear infinite

  &.large
    width: 80px
    height: 80px
    &:after
      width: 64px
      height: 64px
      margin: 8px
      border-width: 6px

  &.medium
    width: 40px
    height: 40px
    &:after
      width: 34px
      height: 34px
      margin: 4px
      border-width: 4px

  &.small
    width: 20px
    height: 20px
    &:after
      width: 16px
      height: 16px
      margin: 2px
      border-width: 2px

@keyframes spinner
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.spinner-container
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: transparentize($lennar-brand-blue, 0.7)
  z-index: 10001
  display: flex
  justify-content: center
  align-items: center
  &.transparent
    background-color: transparent
  &.spinner-container-global
    position: fixed
