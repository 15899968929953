// GenerateSpinner.sass
@import '../../../styles/colors'

.generate-spinner
  display: inline-block
  background: url("./../../../../public/assets/icons/generate.svg") no-repeat center center / contain
  animation: spin 1.2s linear infinite

  &.large
    width: 80px
    height: 80px

  &.medium
    width: 40px
    height: 40px

  &.small
    width: 24px
    height: 24px

@keyframes spin
  0%
    transform: rotate(360deg)
  100%
    transform: rotate(0deg)
