@import '../../../styles/colors'
@import '../../../styles/variables.sass'

$left-gutter-size: 39px
$right-gutter-size: 28px

.modal-corePlan
  width: 100% !important
  max-height: 80%
  h3
    font-style: normal
    font-size: 18px
    line-height: 64px
    color: $lennar-brand-blue
    margin: 0 0 30px 0
  @media (min-width: $modal-corePlan-default-screen-width)
    width: 1055px !important
  .ant-tabs-content-holder
    height: unset
  .ant-modal-content
    box-shadow: none
    border-radius: 0
    position: relative
    min-height: 765px
  .ant-modal-header
    padding: 41px $right-gutter-size 32px $left-gutter-size
    border-bottom: none
    position: relative
    &::after
      content: ""
      position: absolute
      bottom: 0
      width: calc(100% - $left-gutter-size - $right-gutter-size)
      height: 1px
      background: $gray
    .ant-modal-title
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 20px
      color: $lennar-brand-blue
  .ant-modal-close
    position: absolute
    top: 22px
    right: 8px
    margin: auto
    width: 54px
    background: url('./../../../../public/assets/icons/icon-close.svg') no-repeat center center / 16px
    svg
      display: none
  .ant-modal-footer
    padding: 0
    border-top: unset
  .ant-modal-body
    padding: 68px 52px 56px

.modal-corePlan
  &.corePlan-details
    .ant-modal-body
      padding: 0 $right-gutter-size 56px $left-gutter-size
.ant-input-group-addon:first-child
  width: 65px
.floor2plate-input
  .ant-input-group-addon:last-child
    position: absolute
    top: 5px
    left: 135px
.ant-form-item-explain
  font-size: 12px
.ant-modal-confirm
  width: 312px !important
  .ant-modal-content
    border-radius: 0
    background: $white
    border: 1px solid $gray
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
  .ant-modal-body
    padding: 21px 20px 29px
  .ant-modal-confirm-title
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: $lennar-text
    margin: 0
    text-align: center
  .anticon
    display: none
  .ant-modal-confirm-btns
    text-align: center
    margin-top: 30px
  a.ant-btn
    padding: 10px 15px !important
  .ant-btn
    padding: 10px 15px
    font-size: 14px
    line-height: 15px
    max-width: 99px
    min-width: 99px
  .ant-btn-default,
  .ant-btn-default:focus
    background: $lennar-dark-blue
    border-color: $lennar-outlines
    color: $lennar-text-white
  .ant-btn-default:hover
    background: $white
    color: $lennar-text
    border-color: $lennar-outlines
