@import './variables.sass'

.form-group
  margin-bottom: 24px
  overflow: hidden
.ant-form .ant-form-item-label
  display: block
  padding: 0 0 14px 0
  label
    font-style: normal
    font-weight: 700
    font-size: 16px
    line-height: 21px
    color: $lennar-brand-blue
.ant-form-item
  margin: 0 0 24px 0
.ant-input
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 22px
  padding: 20px 16px
  border-color: $default-dark-gray
  min-width: 100%
  border-radius: 0
  &:hover,
  &:focus
    border-color: $lennar-brand-blue
    outline: none
    box-shadow: none
.label-upload
  display: block
.ant-upload
  display: block
  .ant-btn
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 21px
    padding: 20px 16px
    background: $white
    width: 100%
    text-align: left
    border-color: $default-dark-gray
    color: $lennar-brand-blue
    border-width: 1px
    &:hover
      border-color: $lennar-brand-blue
      background-color: $white
      color: $lennar-brand-blue
.ant-upload-text-icon
  width: 35px
  height: 20.61px
  color: $lennar-brand-blue
  background: $teal
  text-align: center
  svg
    fill: $white
.ant-upload-list-item-name
  font-style: normal
  font-weight: 700
  font-size: 10px
  line-height: 20px
  padding: 0 17px 0 8px
.ant-upload-list-text-container
  display: inline-block
.ant-upload-list-item-card-actions
  width: auto
textarea.ant-input
  padding: 6px 16px
  &:focus
    outline: none
.ant-input
  &#basic_squarefootage,
  &#basic_lotSiz
    min-width: 200px
  &#basic_floorHeight,
  &#basic_bathrooms
    min-width: 50px
    max-width: 50px
    // width: 50px
.ant-input-group-addon
  background: none
  border: none
  font-style: normal
  font-weight: 700
  font-size: 10px
  line-height: 1
  padding-right: 4px
  padding-left: 4px
  vertical-align: bottom
  color: $lennar-brand-blue
.ant-radio-button-wrapper
  border-color: $default-dark-gray
  color: $lennar-brand-blue
  font-style: normal
  font-weight: 700
  font-size: 12px
  line-height: 16px
  padding: 12px
  min-width: 40px
  height: 40px
  text-align: center
  &:hover,
  &:focus,
  &:not(.ant-radio-button-wrapper-disabled):focus-within
    box-shadow: none
  &.ant-radio-button-wrapper-checked
    background: $lennar-brand-blue
    color: $white
    &:hover,
    &:focus
      background: $lennar-brand-blue
      color: $white
      border-color: $default-dark-gray
      box-shadow: none
    &::hover,
    &::before
      content: none
  &.ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked)
    color: rgba(0, 0, 0, 0.25)
    background-color: #f5f5f5
    border-color: $gray6
    &::before
      content: none
  &:first-child,
  &:last-child,
  &:not(:first-child):before
    border-radius: 0
    border-color: $default-dark-gray
  &:not(:first-child):before
    background: $default-dark-gray
.ant-tooltip-open
  background: $lennar-brand-blue
.ant-form-item-control-input-content
  text-align: right
.ant-form-item-row
  .ant-col
    width: 100%
.btn-group
  &.right
    text-align: right
  &.between
    display: flex
    justify-content: space-between

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
/* Firefox */
input[type=number]
  -moz-appearance: textfield

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before
  content: none

::placeholder
  color: $default-medium-gray-2 !important
  opacity: 1
:-ms-input-placeholder
  color: $default-medium-gray-2 !important
::-ms-input-placeholder
  color: $default-medium-gray-2 !important