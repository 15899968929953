@import '../../../styles/colors'

.card-corePlan
  border: 0.5px solid $lennar-brand-blue
  background: transparent
  margin: 0 0 48px 0
  border-radius: 0
  /* REMOVE onClick= cursor: pointer */
  outline: 0px solid $lennar-brand-blue
  transition: all 0.3s ease
  &:hover
    border-color: $lennar-brand-blue
    outline: 1px solid $lennar-brand-blue
  .ant-card-head
    padding: 0 7px
    border: none
    min-height: 28px
    .ant-card-head-title
      font-weight: 500
      font-size: 16px
      line-height: 24px
      padding: 7px 0 0 0
      margin-bottom: 4px
  .ant-card-body
    margin-top: 5px
    padding: 0 10px 0 16px
    .room-type-info
      font-weight: 400
      font-size: 12px
      line-height: 20px
    .room-type-element
      display: flex
      background: transparent
      &:hover
       background: transparent
  .client
    font-weight: 400
    font-size: 14px
    line-height: 20px
    min-height: 20px
    margin-bottom: 13px
    p
      margin: 0
  .attributes-list
    display: flex
    justify-content: space-between
    width: 100%
    ul
      list-style: none
      padding: 0
      margin: 0
      line-height: 1
      li
        display: inline-block
        + li
          .room-type-element
            margin-left: 16px
  .icon
    width: 18px
    height: 18px
    background-size: 18px auto
  &.green
    background: $lennar-brand-blue
    color: $white
    .ant-card-head-title,
    .client p
      color: $white
  &.gray
    background: $default-light-gray-3
  .attributes-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 14px
    .dropdown-edit-button
      min-width: 17px
      border-style: none
      padding: 0 14px 14px 14px
      text-align: center
      font-size: 26px
      line-height: 1
      letter-spacing: 2px
      color: $lennar-brand-blue
      background: transparent
      &:hover
        color: $lennar-brand-blue
  .corePlan-name
    display: block
    font-size: 18px
    font-weight: 500
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  .corePlan-series
    display: block
    font-size: 16px
    font-weight: 400
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

.ant-dropdown-menu.dropdown-edit
  padding: 9px 0 0 0
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15)
  li.ant-dropdown-menu-item
    padding: 6px 28px 6px 23px
    border: none
    position: relative
    color: $lennar-brand-blue
    background: transparent
    font-size: 14px
    line-height: 20px
    &::before
      content: ""
      display: block
      position: absolute
      top: 0
      left: 0
      bottom: 0
      margin: auto
      background: rgba(0,0,0,0)
      width: 100%
      height: calc(100% + 2px)
      transition: ease 0.3s background
    &.ant-dropdown-menu-item-active
      background: transparent
      &:hover
        color: $lennar-text
        &::before
          background: $lennar-button-hover
    &.ant-dropdown-menu-item-disabled
      background: $gray2
    &:last-child
      padding-top: 12px
      padding-bottom: 12px
      margin-top: 6px
      &::before
        height: 100%
      &:after
        content: ""
        display: block
        position: absolute
        top: 0
        left: 15px
        width: calc(100% - 30px)
        height: 1px
        background: $gray1
  span
    position: relative

.ant-tooltip-placement-top
  .ant-tooltip-content
    margin-bottom: -22px
.ant-tooltip-placement-right
  .ant-tooltip-content
    margin-left: -57px
