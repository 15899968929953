@import '../../../../styles/colors'

$field-name-width: 160px
$field-input-width: 62px
$mep-field-input-width: 180px
$color-field-name-width: 176px
$color-field-input-width: 66px
.settings-tab

  .mep-category-settings .ant-form-item .ant-input-group-addon:first-child
    min-width: $mep-field-input-width
  .sub-category-name
    font-size: 14px
    font-weight: 500
    line-height: 20px
    margin-bottom: 20px
    min-height: 20px
  .sub-category-break
    height: 30px
  .ant-form-item
    margin-bottom: 10px
    .ant-form-item-control-input
      min-height: unset
    .ant-form-item-explain
      position: absolute
      left: $field-name-width
      z-index: 2
      background-color: $red
      font-size: 10px
      padding: 2px 4px
      width: unset
      border-radius: 2px
      .ant-form-item-explain-error
        color: $white
        white-space: nowrap
  .ant-input-group-wrapper
    width: unset
    color: $lennar-brand-blue
    .ant-input-group-addon
      font-size: 10px
      font-weight: 400
      line-height: 20px
    .ant-input-group-addon:first-child
      text-align: left
      min-width: $field-name-width
      padding: 0 4px 0 0
      color: $lennar-text
    .ant-input-group-addon:last-child
      color: $default-medium-gray-2
      padding: 0 0 0 4px
    .ant-input
      border: 1px solid $default-medium-gray-1
      border-width: 1px
      width: $field-input-width
      padding: 0 0 0 6px
      height: 20px !important
      min-width: unset
      font-size: 10px
      font-weight: 500
  .color-input
    position: relative
    .color-rectangle
      position: absolute
      left: 145px
      height: 20px
      min-width: unset
      width: 20px
      padding: 0
      border: none
      visibility: visible
    .ant-input
      width: $color-field-input-width
    .ant-input-group-addon:first-child
      min-width: $color-field-name-width
    .ant-form-item-explain
      left: $color-field-name-width
.color-picker-popover
  padding-top: 0
  padding-left: 60px
  .ant-popover-arrow
    display: none
  .ant-popover-inner-content
    padding: 0
    .chrome-picker> div:last-child > div:last-child
      display: none !important
form
  .buttons-row
    justify-content: space-between
    width: 100%
    .left-buttons
      align-self: flex-start
    .right-buttons
      display: flex