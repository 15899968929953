@import '../../../styles/colors.sass'

.prompt-modal
  .ant-modal-header
    border-bottom: none

  .ant-modal-content
    width: 450px

    .ant-modal-body
      padding: 10px 24px

  .ant-modal-footer
    border-top: none

    .ant-btn
      min-width: 100px
      min-height: 40px
      padding: 10px 15px

  .prompt-input
    .ant-input
      padding: 0 4px
      font-weight: 500
      font-size: 14px
      line-height: 33px
      border-color: $default-medium-gray-1
      background: $white
      color: $lennar-brand-blue

    .ant-input-group-addon
      color: $default-medium-gray-2
      font-size: 14px
      line-height: 35px
      font-weight: 400
      user-select: none
