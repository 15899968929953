@import '../../../../styles/colors'


.core-plan-variations-container
  display: flex
  flex-direction: column
  max-height: 50vh

.tab-core-plan-variations-title
  height: 30px
.tab-core-plan-variations-list
  margin-top: -10px
  overflow: auto
  flex: 1
  
  .item
    margin-top: 10px
    border: 1px solid $gray
    background: $gray3
    padding: 0 15px 10px 15px
    margin-bottom: 12px
    padding-right: 100px
    min-height: 200px
    display: flex
    flex-direction: column
    align-items: flex-start  // Align items to the top
    border-radius: 10px
    color: #002645
    
    &:hover
      color: $lennar-brand-blue

    .title
      display: flex
      align-items: center
      justify-content: flex-start
      padding: 19px 0px 10px 0px
      p
        font-weight: 400
        font-size: 14px
        line-height: 20px
        margin: 0
        overflow-wrap: anywhere

    .downloadToolTip
     .ant-btn
      border: none
      position: absolute
      padding: 0
      text-align: center
      background: transparent
      box-shadow: none
      min-width: 40px
      max-width: 50%
      display: flex
      align-items: center
      justify-content: center
      height: 40px
      span.icon
        background-size: cover
        width: 24px
        height: 24px
        margin: auto
        display: block
      &:hover
        box-shadow: 0px -2px 2px rgba($gray5, 0.25)
        border-radius: 3px
        background: $white
      &:focus
        color: $lennar-brand-blue
      &:disabled span.icon
      filter: grayscale(100%)  // Apply grayscale filter when the button is disabled
      opacity: 0.5  // Reduce opacity to make it look disabled

    .icon-button
      border: none
      position: absolute
      top: 10px
      padding: 0
      text-align: center
      background: transparent
      box-shadow: none
      min-width: 40px
      max-width: 50%
      display: flex
      align-items: center
      justify-content: center
      height: 40px
      span.icon
        background-size: cover
        width: 24px
        height: 24px
        margin: auto
        display: block
      &:hover
        box-shadow: 0px -2px 2px rgba($gray5, 0.25)
        border-radius: 3px
        background: $white
      &:focus
        color: $lennar-brand-blue

    .btn-generate
      right: 50px

    .btn-download
      right: 10px
      span.icon
        background-image: url('./../../../../../public/assets/download.svg')

    .btn-download:disabled span.icon
      filter: grayscale(100%)  // Apply grayscale filter when the button is disabled
      opacity: 0.5  // Reduce opacity to make it look disabled

    .snapshot-thumbnails
      display: flex
      gap: 10px  // Add spacing between images

    .snapshot-thumbnail
      width: 135px
      height: auto

    .material-thumbnails
      display: grid
      grid-template-columns: repeat(5, 1fr)  // Max 5 thumbnails per row
      gap: 10px  // Add spacing between images
      margin-top: 10px

    .material-thumbnail
      width: 48px
      height: 48px