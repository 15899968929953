@import '../../../styles/colors.sass'

.section-auth
  width: 545px
  margin: 0 auto
  text-align: center
  height: 100vh
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  h1
    color: $lennar-text
    font-weight: 400
    font-size: 20px
    line-height: 50px
    margin-bottom: 64px
    font-family: Inter
  .logo
    margin-bottom: 76px
    position: relative
    height: 110px

  .ant-btn
    width: 208px
    font-size: 20px
    font-family: Inter
    font-weight: 400
    line-height: 30.26px
    padding: 16px 32px
    
  .ant-btn-primary
    background: #003C77
    border: 1px solid #003C77

    &:hover
      background: #fff

  .ant-btn-default
     border: 1px solid #003C77

     &:hover
      background: #003C77

  .expired-session
    margin-top: -54px
    margin-bottom: 54px
    font-size: 1rem
    color: $red