@import '../../../styles/colors'


.filter-corePlans
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100%
  padding: 40px 3px
  margin: 0

  .filter-container
    display: flex
    align-items: center

  .search-container
    width: 233px



  .ant-row
    display: flex
    align-items: center
  h2
    font-weight: 500
    font-size: 12px
    font-family: DM Sans
    font-weight: 500
    line-height: 16px
    margin-bottom: 16px
    color: #0D2727
  h3
    font-weight: 500
    font-size: 12px
    line-height: 16px
    margin-bottom: 16px
  .filter-item
    position: relative
    &.search
      .anticon
        position: absolute
        top: 0
        bottom: 0
        left: 0
        margin: auto
        z-index: 10
        color: $default-medium-gray-2
        font-size: 14px
        line-height: 1
        height: 100%
        padding: 8px 6px
        transform: rotate(90deg)
        transform-origin: center
        cursor: pointer
        text-align: center
        
      input
        font-size: 12px
        line-height: 16px
        padding-left: 15px
      .ant-input-affix-wrapper
        border: 1px solid $default-medium-gray-2
    &.type
      display: block
      text-align: center
      label.ant-radio-button-wrapper
        border-width: 0 0 1px 0
        border-bottom-color: $gray
        width: auto
        font-weight: 500
        font-size: 12px
        line-height: 16px
        color: $gray
        padding: 8px 16px
        height: auto
        & + label
          margin-left: 1px
        &:before,
        &::after
          content: none
        &:focus,
        &:hover
          box-shadow: none
          border-block-color: $lennar-outlines
          color: $lennar-brand-blue
        &.ant-radio-button-wrapper-checked
          border-block-color: $lennar-brand-blue
          color: $lennar-brand-blue
          background: none
          &:focus,
          &:hover
            box-shadow: none
  .filter-attributes
    display: flex
    .ant-select
      text-align: center
      font-weight: 400
      font-size: 12px
      margin-right: 2px
      // line-height: 20px
    .ant-select-selector
      width: auto
      padding: 0 5px 0 22px
    .select-floors
      background: url('./../../../../public/assets/icons/floors.svg') no-repeat center left 0 / 18px
    .select-bedrooms
      background: url('./../../../../public/assets/icons/bedrooms.svg') no-repeat center left 0 / 18px
    .select-bathrooms
      background: url('./../../../../public/assets/icons/bathrooms.svg') no-repeat center left 0 / 18px
    .select-car-spaces
      background: url('./../../../../public/assets/icons/garage.svg') no-repeat center left 0 / 18px
    .select-lot-width
      background: url('./../../../../public/assets/icons/width.svg') no-repeat center left 0 / 18px
  .filter-sort
    display: flex
    align-items: center
    label,
    .ant-select-selection-item
      font-weight: 500
      font-size: 14px
      line-height: 20px
      color: #0D2727
    .ant-select-selection-item
      display: flex
      align-items: center
      padding-right: 20px
    .ant-select-selector
      padding: 0 6px
  .filter-wrapper
    display: flex
    justify-content: flex-end