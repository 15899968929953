@import '../../../../../styles/colors'

.room-catalog
  .empty-message
    text-align: center
    padding-top: 16px
    font-size: 12px
  .ant-collapse-header
    padding: 12px
    .ant-collapse-expand-icon
      position: absolute
      top: 0
      right: 0
      bottom: 0
      margin: auto
      width: 24px
      height: 24px
      transform: rotate(-90deg)
      transform-origin: center
      transition: transform 0.3s ease
      background: url("../../../../../../public/assets/icons/union-black.svg") no-repeat center / 8.75px auto
      svg
        display: none
  .category-collapse
    &>.ant-collapse-content>.ant-collapse-content-box
      padding: 0
    .function-collapse
      &>.ant-collapse-header
        padding-left: 40px
      &>.ant-collapse-content>.ant-collapse-content-box
        padding-left: 20px
        padding-right: 20px
  .ant-collapse,
  .ant-collapse-item
    border: none
  .ant-collapse-item
    &:hover
      .ant-collapse-header-text
        color: $teal
    &.ant-collapse-item-active
      .ant-collapse-header-text
        color: $teal
    &:not(.ant-collapse-item-active)
      .ant-collapse-header-text
        color: $lennar-brand-blue
      .ant-collapse-expand-icon
        transform: rotate(0)
  .ant-collapse-header-text
    padding: 0
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 16px
    color: $lennar-brand-blue
  .ant-collapse-content
    background: transparent
    border: none
    .preview
      padding: 5px 10px
      font-size: 8px
      line-height: 20px

    .thumbnail
      display: block
      position: relative
      box-sizing: border-box
      min-height: 136px
      min-width: 136px
      border: 0.1rem solid transparent
      transition: border-color 0.33s
      &:hover,
      &:active
        border: 0.1rem solid $teal
      &.inactive
        opacity: 0.75
        &:hover,
        &:active
        border-color: transparent
      img
        max-width: 100%
        width: 100%
        margin: auto 0
    .room-name
      overflow: hidden
      text-overflow: ellipsis
      color: $lennar-brand-blue
    .ant-collapse-content-box
      padding: 0 16px
  .modifications
    padding-left: 16px
    .thumbnail
      padding: 5px 10px
    p
      font-style: normal
      font-weight: 500
      font-size: 10px
      line-height: 20px
      margin-bottom: 0

  .obsolete-room-container
    margin-bottom: 16px

    hr
      background: $default-medium-gray-1
      margin: 8px 0 16px 0
      height: 1px
      border: none

    .obsolete-row
      width: 300px
      margin: 0 auto
      align-items: center
      justify-content: center
      padding: 5px
      background: $lennar-brand-blue
      color: $white
      cursor: pointer

      .text
        font-style: normal
        font-weight: 400
        font-size: 12px
        line-height: 16px

      .reload-icon
        position: absolute
        right: 32px
