@import '../../../../styles/colors'

.tabs-corePlan
  padding: 0
  .ant-tabs-nav
    padding: 30px 0 30px
    margin-bottom: 20px !important
    border-bottom: 1px solid $gray
    &::before
      content: none
    .ant-tabs-nav-list
      flex-wrap: wrap
      row-gap: 20px
  .ant-tabs-nav
  .ant-tabs-tab
    display: flex
    justify-content: center
    background: $gray
    border: none
    padding: 6px 32px 7px
    border-radius: 50px
    color: $lennar-brand-blue
    margin: 0 10px
    text-shadow: none
    width: auto
    min-width: 132px
    .ant-tabs-tab-btn
      font-weight: 400
      font-size: 14px
      line-height: 20px
      color: $lennar-brand-blue
    &.ant-tabs-tab-active
      background: $lennar-brand-blue
      .ant-tabs-tab-btn
        color: $lennar-brand-blue
  .ant-tabs-ink-bar
    display: none
