$leftBarWidth: 360px
$defaultWidth: 1250px
$modal-corePlan-default-screen-width: 1090px

$default-font: 'Inter', sans-serif
$font-heading: 'Inter', sans-serif



// New design Font size

$body-extra-small: 12px // Extra small
$body-small: 13px // Small
$regular: 14px // Medium
$subtitle-1: 16px // Large
$subtitle-2: 18px // Extra large
$heading: 20px // Extra extra large
